

















































































































































































import useSelectItems from "@/use/selectItems";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
} from "@vue/composition-api";
import validator from "validator";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        isActive: false,
        name: "",
        city: "",
        street: "",
        postalCode: "",
        nip: "",
        phoneNumber: "",
        note: "",
        representativeFirstName: "",
        representativeLastName: "",
        representativePhoneNumber: "",
        representativeEmail: "",
        invoiceNumber: "",
        dateOfInvoice: "",
        invoicePaymentDate: "",
        package: "",
      }),
    },
  },

  setup(props, { root }) {
    const { languageItems } = useSelectItems({ root });
    const state = reactive({
      dateOfInvoiceClose: false,
      invoicePaymentDateClose: false,
      nowDate: "",
      minDate: computed(() => props.value.startDate),
      endDate: computed(() => props.value.endDate),
      amount: false,
    });

    const nowDate = () => {
      state.nowDate = new Date().toISOString().slice(0, 10);
    };

    onMounted(() => {
      nowDate();
    });

    const rules = {
      postalCode: [
        (v: string) =>
          !v ||
          validator.isPostalCode(v, "PL") ||
          root.$tc("layout.misc.validZipCode"),
      ],
      phoneNumber: [
        (v: string) =>
          !v ||
          validator.isMobilePhone(v, ["pl-PL"]) ||
          root.$tc("layout.misc.validMobilePhone"),
      ],
      email: [
        (v: string) =>
          !v ||
          validator.isEmail(v) ||
          root.$tc("layout.misc.validEmailAddress"),
      ],
      phoneNumberBasic: [
        (v: string) =>
          !v ||
          (v.length >= 9 && validator.isNumeric(v, { no_symbols: true })) ||
          root.$tc("layout.misc.validMobilePhone"),
      ],
      nipNumber: [
        (v: string) =>
          !v ||
          (v.length >= 8 && validator.isNumeric(v, { no_symbols: true })) ||
          root.$tc("layout.misc.validNipNumber"),
      ],
    };

    return { state, rules, languageItems };
  },
});
